let instance;

import { remove_token,remove_get_user_detail, get_user_detail, set_axios_defaults, set_token, get_token,set_user_detail, set_user_settings } from '../helper/helper';
import config from './../config'
import Login from "../apis/hospital";
import Logout from "../apis/hospital";
import Vue from 'vue';
import Swal from 'sweetalert2'

export const useAuth = (options = {}) => {
    if (instance) return instance;
    instance = new Vue({
        data() {
            return {
                config: config,
                user: null,
                setting: null,
                languages: [],
                logging_done: false,
                restaurant_id: 0,
                google_map_key: null,
                init: false
            }
        },
        methods: {
            login(request, callback) {
                Login.adminLogin(request).then((response) => {                     
                    if (response.data.response_code == 200) {
                        let token = response.data.access_token;
                        if (token) {
                            this.user = response.data.data; 
                            this.logging_done = true;
                            set_token(token)
                            set_axios_defaults(token)
                            set_user_detail(response.data.data)
                            set_user_settings(response.data.currency_data)
                            if (this.user.is_master_user){
                                callback('master-admin')
                            } else {
                                callback('home')
                            }
                        } else {
                            this.$toasted.error(response.data.message, { duration: 2000 });
                            callback('login')
                        }
                    } else if(response.data.response_code == 202) {
                        callback('login')
                        Swal.fire({
                            title:response.data.message,
                            showClass: {
                                popup: `
                                  animate__animated
                                  animate__fadeInUp
                                  animate__faster
                                `
                              },
                              hideClass: {
                                popup: `
                                  animate__animated
                                  animate__fadeOutDown
                                  animate__faster
                                `
                              }}
                            );
                    }else {
                        this.$toasted.error(response.data.message, { duration: 2000 });
                        callback('login')
                    }
                });
            },
            logout(data, callback) {
                //Logout.CheckLogout(data).then(res => {
                    this.logging_done = false;
                    this.user = '';
                    remove_token();
                    remove_get_user_detail();
                    callback('login')
                //});
            },
            setUser(callback, token = "") {
                if (token || get_token()) {
                    set_axios_defaults(token || get_token());
                    this.user = get_user_detail();                      
                    if (this.user.is_master_user){
                        callback('master-admin')
                    } else {
                        callback('home')
                    }
                } else {
                    this.logging_done = false;
                    callback('login')
                }
            },
        }
    });
    return instance;
}

export default {
    install(Vue, options = {}) {
        Vue.prototype.$auth = useAuth(options);
    }
};