import Vue from 'vue'
import VueRouter from 'vue-router'
import Restaurant from './restaurant/index'
import Base from '../views/Base.vue'
Vue.use(VueRouter)
const routes = [
  ...Restaurant,
]

const router = new VueRouter({
    mode: 'history',
    routes: [{
      base: '',
      component: Base,
      path: '/',
      children: routes
    },{
      path: '/',
      redirect: '/',
    }],
    scrollBehavior(to, from, savedPosition) { return { x: 0, y: 0 } }
});

router.beforeEach((to, from, next) => {
    var noauth = ['login', 'forgotpassword', 'resetpasswords'];
    if (!noauth.includes(to.name)) { //InAuthRoute
        if (!Vue.prototype.$auth || !Vue.prototype.$auth.user) {
          Vue.prototype.$auth.setUser((type) => {
            if (noauth.includes(type)) {
              if (type == 'login') { //Token not found
                next({ name: type });
              } else { //User set now
                  if (Vue.prototype.$auth.user.is_master_user) {
                    if (to.name.includes("master")) {
                        next();
                    } else {
                        next({ name: "login" }); // Master Home
                    }
                  } else {
                    if (to.name.includes("master")) {
                        next({ name: "login" }); // Normal Home
                    } else {
                        next();
                    }
                  }
                }
            } else {
              if (Vue.prototype.$auth.user.is_master_user) {
                if (to.name.includes("master")) {
                    next();
                } else {
                    next({ name: "login" }); // Master Home
                }
              } else {
                if (to.name.includes("master")) {
                    next({ name: "login" }); // Normal Home
                } else {
                    next();
                }
              }
            }
          });
        } else {
              if (Vue.prototype.$auth.user.is_master_user) {
                if (to.name.includes("master")) {
                    next();
                } else {
                    next({ name: "login" }); // Master Home
                }
              } else {
                if (to.name.includes("master")) {
                    next({ name: "login" }); // Normal Home
                } else {
                    next();
                }
              }
            } // Add Condition for master admin

  } else { //NoAuthRoute
    next();
  }
});
export default router