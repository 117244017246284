import config from "../config";
import router from "../router"
import moment from 'moment';
import Vue from "vue";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Cookies from 'js-cookie';

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};
Vue.use(VueSweetalert2, options);

const debounce = (func, delay) => {
    let debounceTimer
    return function() {
        const context = this
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer
            = setTimeout(() => func.apply(context, args), delay)
    }
}

const set_axios_defaults = (token) => {
    window.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    window.axios.defaults.headers.common["Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    window.axios.defaults.headers.common["DeviceId"] = Cookies.get('device_id');
    window.axios.defaults.headers.common["Accept"] = "application/json";
    window.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    window.axios.interceptors.response.use(
        (res => {
            if(res.data.response_code == 104 && router.history.current.name != "subscription"){
                Vue.swal({
                  title: "<h3><strong>Subscription Expire Notification</strong></h3>",
                    icon: "info",
                    html: res.data.message,
                    showCloseButton: false,
                    showCancelButton: true,
                    focusConfirm: false,
                    allowOutsideClick: false,
                    confirmButtonText: "Subscribe Now",
                    cancelButtonText: "Close",
                }).then((result) => {
                    if (result.isConfirmed) {
                        router.push({ name: 'subscription' }).catch(() => {})
                        Vue.toasted.error(res.data.message, {duration: 2000});
                        document.getElementsByClassName('text-muted').text ="EWEw";
                    }
                });
             }
             if(res.axiosFailsDueToNoInternetConnection){
                Vue.toasted.error('no internet connection', {duration: 2000}); 
                 dispatch({type: RELOAD});
            }
            return res;
        }),
        debounce(err => {
            if (err.response.status === 401 || err.response.status === 403) {
                if (window.axios.defaults.headers.common.Authorization !== 'Bearer null') {
                    Vue.toasted.error('Session Expired. Try login again', {duration: 2000});
                   
                }
                remove_token()
                Vue.prototype.$auth.user = null;
                router.push({ name: 'login' }).catch(() => {})
            } else if (err.response.status === 500) {
                Vue.toasted.error('Server error', {duration: 2000});                
            }  else if (err.response.status === 103) {
                Vue.toasted.error('You don\'t have permission to do this', {duration: 2000});
            } else if (err.response.status === 422) {
                var message = Object.values(err.response.data.errors)[0][0]
                Vue.toasted.error(message, {duration: 2000});                
            }else if (err.response.status === 104) {
                var message = Object.values(err.response.data.errors)[0][0]
                Vue.toasted.error(message, {duration: 2000});                
            } 
            else if(error.toJSON().message === 'Network Error' || error.code ===  ERR_NETWORK){
            ///    alert('no internet connection');
                Vue.toasted.error('no internet connection', {duration: 2000});   
                dispatch({type: RELOAD});
            }
            throw err;
        }, 500)
    );
}

const set_user_detail = (details) => {
    localStorage.setItem('user_details', JSON.stringify(details));
}
const set_user_settings = (details) => {
    localStorage.setItem('user_settings', JSON.stringify(details));
}
const get_user_settings = (details) => {
    return JSON.parse(localStorage.getItem('user_settings'));
}

const get_user_detail = () => {
    //return Vue.prototype.$auth.setting;
    return JSON.parse(localStorage.getItem('user_details'));
}

const set_user = (user) => {
    // localStorage.setItem('user', JSON.stringify(user));
    // if(user.setting && user.setting.settings){
    //   localStorage.setItem('setting', JSON.stringify(user.setting.settings));
    // }
}

const get_user = () => {
    return Vue.prototype.$auth.user;
    // return JSON.parse(localStorage.getItem('user'));
}

const multipart_headers = () => ({ 'Content-Type': 'multipart/form-data' })
    //const multipart_headers = () => ({'Content-Type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2)})

// error messages
const error_message = (message) => Vue.toasted.error(message, { duration: 2000 });
const success_message = (message) => Vue.toasted.success(message, { duration: 2000 });
const toastr_message = (message, type) => Vue.toasted[type](message, { duration: 2000 });

const set_token = (token) => localStorage.setItem('token', token)
const get_token = () => localStorage.getItem('token')
const remove_token = () => localStorage.removeItem('token');

const remove_get_user = () => localStorage.removeItem('user')
const remove_get_user_detail = () => localStorage.removeItem('user_details')

const replaceByDefault = (event) => (event.target.src = config.no_image)

export {
    debounce,
    set_axios_defaults,
    set_user_detail,
    set_user,
    get_user,
    get_user_detail,
    error_message,
    success_message,
    toastr_message,
    multipart_headers,
    replaceByDefault,
    remove_get_user,
    remove_get_user_detail,
    set_token,
    get_token,
    remove_token,
    set_user_settings,
    get_user_settings,
};