import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from "vuelidate";
import Toasted from 'vue-toasted';
import VueCountryCode from "vue-country-code-select";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import axios from 'axios';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import config from './config';
import Moment from 'moment';
import jQuery from 'jquery';
import infiniteScroll from 'vue-infinite-scroll';
import AuthPlugin from './plugins/authPlugin';
import '@/assets/css/style.css';
import '@/assets/css/custom.css';
import '@/assets/vendors/mdi/css/materialdesignicons.min.css';
import '@/assets/vendors/css/vendor.bundle.base.css';
import VoerroTagsInput from '@voerro/vue-tagsinput';
import moment from 'moment';
import titleMixin from './titleMixin'

window.jQuery = jQuery;
window.axios = axios;
require('dotenv').config()
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(VueCountryCode);
Vue.use(Moment);
Vue.use(infiniteScroll);
Vue.component('tags-input', VoerroTagsInput);
Vue.mixin(titleMixin)


Vue.use(Toasted, {
    duration: 4000,
    position: 'top-right',
    action: {
        onClick: (e, toastObject) => {
            toastObject.goAway(0);
        }
    }
});

Vue.use(AuthPlugin);
Vue.config.productionTip = false

window.axios.defaults.baseURL = config.api_url;
window.axios.defaults.headers.common['Content-Type'] = 'application/json';

new Vue({
    router,
    store,
    directives: { infiniteScroll },    
    render: h => h(App)
}).$mount('#app')

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment.parseZone(String(value)).format('DD MMM YYYY, hh:mm a')
    }
});