import hospitalApi from '../apis/hospital';

const state = {};
const actions = {
    getOpdListData({ commit }, data) {
        return hospitalApi.getOpdList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    redeemSubscriptionData({ commit }, data) {
        return hospitalApi.redeemSubscription(data).then(response => {
            if (response.data.code == 200) {

                return response.data;
            }
            return response.data;
        })
    },
    subcriptionListData({ commit }, data) {
        return hospitalApi.getsubcriptionList(data).then(response => {
            if (response.data.code == 200) {

                return response.data;
            }
            return response.data;
        })
    },
    getPatientsListData({ commit }, data) {
        return hospitalApi.getPatientsList(data).then(response => {
            if (response.data.code == 200) {

                return response.data;
            }
            return response.data;
        })
    },
    getDoctorListData({ commit }, data) {
        return hospitalApi.getDoctorList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getCityListData({ commit }, data) {
        return hospitalApi.getCityList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    registerOpdData({ commit }, data) {
        return hospitalApi.registerOpd(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    editPatientData({ commit }, data) {
        return hospitalApi.editPatient(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getAppointmentListData({ commit }, data) {
        return hospitalApi.getAppointmentList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    postAddDoctorData({ commit }, data) {
        return hospitalApi.postAddDoctor(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getDoctorsDetailsData({ commit }, data) {
        return hospitalApi.getDoctorList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getAppointmentDetailsData({ commit }, data) {
        return hospitalApi.getAppointmentDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getPatientsByNumberData({ commit }, data) {
        return hospitalApi.getPatientsByNumber(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getOPDDepartmentListData({ commit }, data) {
        return hospitalApi.getOPDDepartmentList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    postEditOpdData({ commit }, data) {
        return hospitalApi.postEditOpd(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getDashboardData({ commit }, data) {
        return hospitalApi.getDashboard(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getAddEditSettingsData({ commit }, data) {
        return hospitalApi.addEditSettings(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getSettingsData({ commit }, data) {
        return hospitalApi.getSettings(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    addCityData({ commit }, data) {
        return hospitalApi.addCity(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    editDoctorDetailsData({ commit }, data) {
        return hospitalApi.editDoctorDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getCityDetailsData({ commit }, data) {
        return hospitalApi.getCityDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    editCityDetailsData({ commit }, data) {
        return hospitalApi.editCityDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getOpdFeesData({ commit }, data) {
        return hospitalApi.getOpdFees(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getMedicineListData({ commit }, data) {
        return hospitalApi.getMedicineList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getICDEffectListData({ commit }, data) {
        return hospitalApi.getICDEffectList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getStatisticsListData({ commit }, data) {
        return hospitalApi.getStatisticsList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getAddPrescriptionData({ commit }, data) {
        return hospitalApi.getAddPrescription(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    addMedicineData({ commit }, data) {
        return hospitalApi.addMedicine(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getMedicineDetailsData({ commit }, data) {
        return hospitalApi.getMedicineDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    editMedicineDetailsData({ commit }, data) {
        return hospitalApi.editMedicineDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getPrescriptionData({ commit }, data) {
        return hospitalApi.getPrescription(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    checkPatientNumberData({ commit }, data) {
        return hospitalApi.checkPatientNumber(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getChangelogListData({ commit }, data) {
        return hospitalApi.getChangelogList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getConcernDoctorListData({ commit }, data) {
        return hospitalApi.getConcernDoctorList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getOPDCityListData({ commit }, data) {
        return hospitalApi.getOPDCityList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getPrescriptionMedicineListData({ commit }, data) {
        return hospitalApi.getPrescriptionMedicineList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getPrescriptionReportListData({ commit }, data) {
        return hospitalApi.getPrescriptionReportList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    resetAdminPasswordData({ commit }, data) {
        return hospitalApi.resetAdminPassword(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    forgotPasswordData({ commit }, data) {
        return hospitalApi.forgotPassword(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getReportsListData({ commit }, data) {
        return hospitalApi.reportsData(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getDepartmentListData({ commit }, data) {
        return hospitalApi.getDepartmentList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getDepartmentDetailData({ commit }, data) {
        return hospitalApi.getDepartmentDetail(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    editDepartmentDetailsData({ commit }, data) {
        return hospitalApi.editDepartmentDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    editDepartmentStatusData({ commit }, data) {
        return hospitalApi.editDepartmentStatus(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    addDepartmentData({ commit }, data) {
        return hospitalApi.addDepartment(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    deleteDepartmentData({ commit }, data) {
        return hospitalApi.deleteDepartment(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getReportListData({ commit }, data) {
        return hospitalApi.getReportList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getReportDetailsData({ commit }, data) {
        return hospitalApi.getReportDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    addReportData({ commit }, data) {
        return hospitalApi.addReportData(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    editReportData({ commit }, data) {
        return hospitalApi.editReportData(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    deleteReportData({ commit }, data) {
        return hospitalApi.deleteReportData(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },


    getEffectListData({ commit }, data) {
        return hospitalApi.getEffectList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getEffectDetailsData({ commit }, data) {
        return hospitalApi.getEffectDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    addEffectData({ commit }, data) {
        return hospitalApi.addEffectData(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    editEffectData({ commit }, data) {
        return hospitalApi.editEffectData(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    deleteEffectData({ commit }, data) {
        return hospitalApi.deleteEffectData(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    logoutAdmin({ commit }, data) {
        return hospitalApi.logoutData(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getAdminDetailsData({ commit }, data) {
        return hospitalApi.getAdminDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    updateBusinessDetailsData({ commit }, data) {
        return hospitalApi.updateBusinessDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    updatePrescriptionBackground({ commit }, data) {
        return hospitalApi.updatePrescriptionBackground(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    deletePrescriptionBackgroundData({ commit }, data) {
        return hospitalApi.deletePrescriptionBackground(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getCurrencies({ commit }, data) {
        return hospitalApi.getCurrencies(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getContactUsRequestList({ commit }, data) {
        return hospitalApi.getContactUsRequestList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getContactUsRequestDetails({ commit }, data) {
        return hospitalApi.getContactUsRequestDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    loginHistoryAPI({ commit }, data) {
        return hospitalApi.loginHistory(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    getSubscriptionPlanListAPI({ commit }, data) {
        return hospitalApi.getSubscriptionPlanList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getSubscriptionPlanDetailsAPI({ commit }, data) {
        return hospitalApi.getSubscriptionPlanDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    addSubscriptionPlanListAPI({ commit }, data) {
        return hospitalApi.addSubscriptionPlanList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    updateSubscriptionPlanAPI({ commit }, data) {
        return hospitalApi.updateSubscriptionPlanList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    updateSubscriptionPlanFeatureAPI({ commit }, data) {
        return hospitalApi.updateSubscriptionPlanFeature(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    updateSubscriptionPlanStatusAPI({ commit }, data) {
        return hospitalApi.updateSubscriptionPlanStatus(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    updateSubscriptionPlanFeatureStatusAPI({ commit }, data) {
        return hospitalApi.updateSubscriptionPlanFeatureStatus(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    deleteSubscriptionPlanAPI({ commit }, data) {
        return hospitalApi.deleteSubscriptionPlan(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    deleteSubscriptionPlanFeatureAPI({ commit }, data) {
        return hospitalApi.deleteSubscriptionPlanFeature(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    getClientListAPI({ commit }, data) {
        return hospitalApi.getClientList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getClientUserListAPI({ commit }, data) {
        return hospitalApi.getClientUserList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getClientSubscriptionAPI({ commit }, data) {
        return hospitalApi.getClientSubscription(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    updateClientSubscriptionAPI({ commit }, data) {
        return hospitalApi.updateClientSubscription(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getClientTransactionAPI({ commit }, data) {
        return hospitalApi.getClientTransaction(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getClientDashboardAPI({ commit }, data) {
        return hospitalApi.getClientDashboard(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    insertCurrencyAPI({ commit }, data) {
        return hospitalApi.insertCurrency(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getMasterAdminDashboardAPI({ commit }, data) {
        return hospitalApi.getMasterAdminDashboard(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getMasterAdminRevenueAPI({ commit }, data) {
        return hospitalApi.getMasterAdminRevenue(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    updateClientDetailsAPI({ commit }, data) {
        return hospitalApi.updateClientDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    getAppointmentDepartmentListAPI({ commit }, data) {
        return hospitalApi.getAppointmentDepartmentList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    updateClientUserDetailsAPI({ commit }, data) {
        return hospitalApi.updateClientUserDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    addClientUserAPI({ commit }, data) {
        return hospitalApi.addClientUser(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    loginClientUserAPI({ commit }, data) {
        return hospitalApi.loginClientUser(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    updateCurrencyStatusAPI({ commit }, data) {
        return hospitalApi.updateCurrencyStatus(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    exportReportsListData({ commit }, data) {
        hospitalApi.exportReportsData(data).then(response => {
            //if (response.data.code == 200) {
            //    return response.data;
            //}
            //return response.data;
            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
                'download',
                `Het Hospital Report.xls`
            )
            document.body.appendChild(link)
            link.click()
        })
    },

    registerOpdAndPrintData({ commit }, data) {
        return hospitalApi.registerOpdAndPrint(data).then(response => {
            const fileUrl = URL.createObjectURL(response.data);
            let iframe = document.createElement("iframe");
            document.body.appendChild(iframe);
            iframe.style.display = "none";
            iframe.src = fileUrl;
            iframe.onload = function () {
                iframe.focus();
                iframe.contentWindow.print();
            };
            return { "response_code": 200, "message": "OPDs found" };
        })
    },

    editOpdAndPrintData({ commit }, data) {
        return hospitalApi.editOPDAndPrint(data).then(response => {
            const fileUrl = URL.createObjectURL(response.data);
            let iframe = document.createElement("iframe");
            document.body.appendChild(iframe);
            iframe.style.display = "none";
            iframe.src = fileUrl;
            iframe.onload = function () {
                iframe.focus();
                iframe.contentWindow.print();
            };
            return { "response_code": 200, "message": "OPDs found" };
        })
    },
    getRoomListData({ commit }, data) {
        return hospitalApi.getRoomList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getRoomDetailsData({ commit }, data) {
        return hospitalApi.getRoomDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    addRoomData({ commit }, data) {
        return hospitalApi.addRoom(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getWardListData({ commit }, data) {
        return hospitalApi.getWardList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getIPDWardListData({ commit }, data) {
        return hospitalApi.getIPDWardList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    editRoomDetailsData({ commit }, data) {
        return hospitalApi.addRoom(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    addWardData({ commit }, data) {
        return hospitalApi.addWard(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getWardDetailsData({ commit }, data) {
        return hospitalApi.getWardDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    editWardDetailsData({ commit }, data) {
        return hospitalApi.addWard(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    deleteRoom({ commit }, data) {
        return hospitalApi.deleteRoomData(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    deleteWard({ commit }, data) {
        return hospitalApi.deleteWardData(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getIpdListData({ commit }, data) {
        return hospitalApi.getIpdList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getIPDRoomListData({ commit }, data) {
        return hospitalApi.getIPDRoomList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getIPDRoomBedListData({ commit }, data) {
        return hospitalApi.getIPDRoomBedList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    admitPatientData({ commit }, data) {
        return hospitalApi.admitPatient(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getIPDDetailsData({ commit }, data) {
        return hospitalApi.getIPDDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    updateIPDDetailsData({ commit }, data) {
        return hospitalApi.updateIPDDetails(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getIPDTreatmentsData({ commit }, data) {
        return hospitalApi.getIPDTreatments(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    deleteTreatmentData({ commit }, data) {
        return hospitalApi.deleteTreatment(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    deleteIPDReportData({ commit }, data) {
        return hospitalApi.deleteIPDReport(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    addIPDTreatmentData({ commit }, data) {
        return hospitalApi.addIPDTreatment(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    editIPDTreatmentData({ commit }, data) {
        return hospitalApi.editIPDTreatment(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    addIPDReportData({ commit }, data) {
        return hospitalApi.addIPDReport(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    editIPDReportData({ commit }, data) {
        return hospitalApi.editIPDReport(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getIPDHistoryData({ commit }, data) {
        return hospitalApi.getIPDHistory(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    closeIPDTreatmentData({ commit }, data) {
        return hospitalApi.closeIPDTreatment(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getBillingData({ commit }, data) {
        return hospitalApi.getBillings(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getPatientBillingData({ commit }, data) {
        return hospitalApi.getPatientBilling(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    payPatientBillingData({ commit }, data) {
        return hospitalApi.payPatientBilling(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    paidPatientBillingData({ commit }, data) {
        return hospitalApi.paidPatientBilling(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getPaymentTransactionsData({ commit }, data) {
        return hospitalApi.getPaymentTransactions(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getTimeZoneListData({ commit }, data) {
        return hospitalApi.getTimeZoneList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    verifyPaymentData({ commit }, data) {
        return hospitalApi.verifyPayment(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getWebSettingsData({ commit }, data) {
        return hospitalApi.getWebSettings(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    updateWebSettingsData({ commit }, data) {
        return hospitalApi.updateWebSettings(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    deleteWebImageData({ commit }, data) {
        return hospitalApi.deleteWebImage(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    addAndVerifyDomainData({ commit }, data) {
        return hospitalApi.addAndVerifyDomain(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    getClientDomainData({ commit }, data) {
        return hospitalApi.getClientDomain(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    deleteProfilePicData({ commit }, data) {
        return hospitalApi.deleteProfilePic(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    downloadPaymentTransactionInvoiceData({ commit }, data) {
        return hospitalApi.downloadPaymentTransactionInvoice(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },
    printOPDData({ commit }, data) {
        return hospitalApi.printOPDDescData(data).then(response => {
            const fileUrl = URL.createObjectURL(response.data);
            let iframe = document.createElement("iframe");
            document.body.appendChild(iframe);
            iframe.style.display = "none";
            iframe.src = fileUrl;
            iframe.onload = function () {
                iframe.focus();
                iframe.contentWindow.print();
            };
            return { "response_code": 200, "message": "OPDs found" };
        })
    },
};
const mutations = {};
const getters = {};
export const hospital = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};